import { config } from '../config';
const currentVersion = '1.0';

/********************************************************************************
 **********************      Wallet Requests      *******************************
 *******************************************************************************/

// all wallet creation needs to be wallet 2.0 from this point onwards
export function createWallet({ walletPasscode, walletMnemonicRecoveryPhrase }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/wallets?core=true`,
    data: {
      mnemonic: walletMnemonicRecoveryPhrase,
      passcode: walletPasscode,
    },
  };
}

export function getUserWalletItem({ symbol, isLegacy }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/wallets/${symbol}${
      isLegacy ? '' : '?core=true'
    }`,
  };
}

export function importWallet({
  walletMnemonicRecoveryPhrase,
  walletPasscode,
  hasLegacyWallet,
}) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/wallets/import${
      hasLegacyWallet ? '?forgotMnemonic=true' : ''
    }`,
    data: {
      mnemonic: walletMnemonicRecoveryPhrase,
      passcode: walletPasscode,
    },
  };
}

export function getWalletMnemonicRecoveryPhrase() {
  return {
    url: `${config.core.walletServiceUrl}/v${currentVersion}/wallets/mnemonic`,
  };
}

export function changeWalletPasscode({ mnemonic, newPasscode, core }) {
  return {
    method: 'PUT',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/wallets/passcode${
      core ? '?core=true' : ''
    }`,
    data: {
      mnemonic,
      newPasscode,
    },
  };
}

/********************************************************************************
 ********************      Transaction Requests      ****************************
 *******************************************************************************/
export function getTransactionHistory({ symbol, page, pageSize, sortBy, sortDir, core }) {
  if (sortBy === 'date') sortBy = 'timestamp';
  const sortByParam = `${sortBy}+${sortDir}`;

  const queryParams = `?page=${page}&pageSize=${pageSize}&sortBy=${sortByParam}${
    core ? '&core=true' : ''
  }`;

  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/wallets/transactions/${symbol}${queryParams}`,
  };
}

export function sendTransaction({ amount, symbol, to, walletPassword, core, gasFee }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/transactions${
      core ? '?core=true' : ''
    }`,
    data: { amount, symbol, to, walletPassword, gasFee },
  };
}

export function sendCoreBridgeTransaction({
  amountToBridge,
  walletPassword,
  tankAmountEth,
  tankTransactionFeeEth,
}) {
  const bridgeVersion = '2.0';

  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${bridgeVersion}/layer2/bridge`,
    data: {
      amountToBridge,
      walletPassword,
      tankAmountEth,
      tankTransactionFeeEth,
    },
  };
}

export function sendL2Transaction({ amount, to, walletPassword, gasFee, core }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/layer2/send${
      core ? '?core=true' : ''
    }`,
    data: { amount, to, walletPassword, gasFee },
  };
}

/********************************************************************************
 **********************     Gas Fee Requests     ********************************
 *******************************************************************************/

export function getL2BridgeGasFee({ amount, core = false }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/layer2/bridge-gas-fee?core=${core}&amount=${amount}`,
  };
}

export function getL2TransferGasFee({ amount = 0, to = '', core = false }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/layer2/transfer-gas-fee?core=${core}&to=${to}&amount=${amount}`,
  };
}

export function getGasFee({ amount, to, symbol = 'ETH', core = false }) {
  let url = `${config.core.walletServiceUrl}/v${currentVersion}/transactions/fee/${symbol}?core=${core}`;
  if (amount && to) {
    url += `&amount=${amount}&to=${to}`;
  }
  return {
    url,
    method: 'GET',
  };
}

export function getL2BridgeGasEthFee() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/layer2/eth-gas-tank`,
  };
}

export function getAcceptedERC20() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/erc20-configurations/allow-purchase`,
  };
}

/** ****** Chain Info ********/
export function getChainInfo() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/chainInfo`,
  };
}

/********************************************************************************
 *********************     Private Token Requests     **************************
 *******************************************************************************/

export function getL2ChainInfo() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/chainInfo/layer2`,
  };
}

export function getPrivateTokenTransactionHistory({
  url,
  page,
  pageSize,
  sortBy,
  sortDir,
}) {
  const queryParams = `?size=${pageSize}&page=${page}&sort=${sortBy}&direction=${sortDir}`;

  return {
    method: 'GET',
    url: `${url}${queryParams}`,
  };
}

/********************************************************************************
 **********************     Coin Conversion     ********************************
 *******************************************************************************/

export function getCoinConversion({ symbol, currency }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/cryptoPrice?symbol=${symbol}&currency=${currency}`,
  };
}

/********************************************************************************
 **********************     BTC Testnet Wallet Migration     ********************************
 *******************************************************************************/

export function btcMigrationRequired() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/wallets/is-btc-migration-required`,
  };
}
export function getMemberSalts() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/wallets/encrypted-mnemonic`,
  };
}

export function updateBtcAddress({ btcAddress }) {
  return {
    method: 'PUT',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/wallets/btc-address`,
    data: {
      btcAddress,
    },
  };
}

export function completeBtcMigration() {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/wallets/complete-btc-migration`,
  };
}
