import { useEffect, useState } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import crypto from 'crypto-browserify';
import * as bitcoin from 'bitcoinjs-lib';
import * as bip39 from 'bip39';
import BIP32Factory from 'bip32';
import dynamic from 'next/dynamic';
// Hooks
import { config } from 'config';
import { useWallet } from '@/hooks/useWallet';

// Dynamically import tiny-secp256k1
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ECPair = dynamic(
  () =>
    import('ecpair').then((mod) => {
      const ecc = import('tiny-secp256k1');
      return mod.ECPairFactory(ecc);
    }),
  { ssr: false }
);

// Components
import {
  AlertBody,
  CopyToClipboard,
  LoadingSpinner,
  PasswordInputIcon,
} from '@/common/index';
// Hooks
import {
  getMemberSalts,
  updateBtcAddress,
  completeBtcMigration,
} from '@/resources/wallet-service.resource';
import { useAuth } from '@/hooks/useAuth';

const initError = {
  error: false,
  message: '',
};

export default function UpdateBTCForm({ handleClose }) {
  const { fetcher, user } = useAuth();
  const { getWalletItems } = useWallet();
  const [passcode, setPasscode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(initError);
  const [encryptedData, setEncryptedData] = useState({});
  const [address, setAddress] = useState('');
  const [fetchWalletLoading, setFetchWalletLoading] = useState(false);

  const getEncryptedData = async () => {
    // get encrypted data from api
    await fetcher(getMemberSalts())
      .then((data) => {
        setEncryptedData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (user.accessToken) getEncryptedData();
  }, [user.accessToken]);

  const handleInput = (e) => {
    setError(initError);
    setPasscode(e.target.value);
  };

  const decryptMnemonic = async () => {
    const cipher = encryptedData.encryptedMnemonic;
    const iv = encryptedData.salt64;

    try {
      const cipherBytes = Buffer.from(cipher, 'base64');
      const keyBytes = crypto
        .createHash('sha256')
        .update(Buffer.from(passcode, 'utf8'))
        .digest();
      const ivBytes = Buffer.from(iv, 'utf8');

      const { key: derivedKey, iv: derivedIV } = createAesInstance(keyBytes, ivBytes);

      const decipher = crypto.createDecipheriv('aes-256-cbc', derivedKey, derivedIV);
      let decrypted = decipher.update(cipherBytes);
      decrypted = Buffer.concat([decrypted, decipher.final()]);

      // Trim zero padding
      let lastZeroIndex = decrypted.length;
      for (let i = decrypted.length - 1; i >= 0; i--) {
        if (decrypted[i] === 0) {
          lastZeroIndex = i;
        } else {
          break;
        }
      }
      decrypted = decrypted.slice(0, lastZeroIndex);
      return decrypted.toString('utf8');
    } catch (err) {
      setError({
        error: true,
        message: 'Decryption failed, please verify the passcode and try again.',
      });
    }
  };

  const isValidMnemonic = (mnemonic) => {
    if (!mnemonic) {
      return false;
    }

    // Split mnemonic into words
    const words = mnemonic.trim().split(/\s+/);

    // Check if mnemonic has exactly 12 words
    if (words.length !== 12) {
      return false;
    }

    // Check if all words are valid BIP39 words
    const wordlist = bip39.wordlists.english;

    for (const word of words) {
      if (!wordlist.includes(word)) {
        return false;
      }
    }
    // Validate mnemonic checksum
    return bip39.validateMnemonic(mnemonic);
  };

  const deriveBitcoinAddress = async (mnemonic, setError) => {
    const ecc = await import('tiny-secp256k1');
    const bip32 = BIP32Factory(ecc);
    const environment = config.environment.toLowerCase(); // Force lowercase
    const isProduction = environment === 'production';
    try {
      const seed = bip39.mnemonicToSeedSync(mnemonic);
      const root = bip32.fromSeed(seed);

      const path = "m/44'/0'/0'/0/0";
      const child = root.derivePath(path);
      const publicKeyPoint = ecc.pointFromScalar(child.privateKey);

      const { address } = bitcoin.payments.p2pkh({
        pubkey: Buffer.from(publicKeyPoint),
        network: isProduction ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
      });
      // Securely wipe sensitive data
      seed.fill(0);
      if (root.privateKey) root.privateKey.fill(0);
      if (child.privateKey) child.privateKey.fill(0);

      return address;
    } catch (err) {
      console.error('Error deriving Bitcoin address:', { err });
      setError({
        error: true,
        message: 'Failed to derive Bitcoin address. Please contact support or try again.',
      });
      return null;
    }
  };

  function createAesInstance(key, iv) {
    const keySize = 256;
    const blockSize = 128;
    const iterations = 1000;

    const derived = crypto.pbkdf2Sync(
      key,
      iv,
      iterations,
      (keySize + blockSize) / 8,
      'sha1'
    );

    return {
      key: derived.slice(0, keySize / 8),
      iv: derived.slice(keySize / 8),
    };
  }

  const secureCleanup = () => {
    setPasscode('');
    setProcessing(false);
    setError(initError);
    setEncryptedData({});
  };

  const handleFormSubmit = async () => {
    decryptMnemonic()
      .then(async (data) => {
        const valid = await isValidMnemonic(data);
        const address = !!valid && (await deriveBitcoinAddress(data, setError));

        !!address && submitAddress(address);
      })
      .catch(() => {
        setError({
          error: true,
          message: 'Decryption failed, please verify the passcode and try again.',
        });
      });
  };

  const submitAddress = async (addressRes) => {
    await fetcher(updateBtcAddress({ btcAddress: addressRes }))
      .then(async () => {
        await fetcher(completeBtcMigration())
          .then(() => {
            setAddress(addressRes);
            setProcessing(false);
            secureCleanup();
          })
          .catch(() => {
            setAddress('');
            setError({
              error: true,
              message: 'Unable to complete BTC migration. Please try again.',
            });
          });
      })
      .catch(() => {
        setAddress('');
        setError({
          error: true,
          message: 'Unable to update BTC address. Please try again.',
        });
      });
  };

  const completeMigration = async () => {
    setFetchWalletLoading(true);
    // refresh the wallet data and close
    await getWalletItems();
    handleClose();
  };

  return (
    <Box sx={{ marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {error.error && (
        <Alert severity="error">
          <AlertBody header="Error" body={error.message} />
        </Alert>
      )}
      {!address ? (
        <>
          <Typography variant="body1" data-core-qa="btcPasscodeText">
            Please enter your wallet passcode to update your Bitcoin address.
          </Typography>
          <TextField
            label="Wallet Passcode"
            id="passcode"
            size="small"
            data-core-qa="btcPasscodeInput"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter wallet passcode"
            value={passcode}
            onChange={(e) => {
              handleInput(e);
            }}
            InputProps={{
              endAdornment: (
                <PasswordInputIcon state={showPassword} setState={setShowPassword} />
              ),
            }}
            fullWidth
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              gap: '16px',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleFormSubmit} disabled={!passcode}>
              {processing ? <LoadingSpinner /> : 'Submit'}
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="body1" data-core-qa="btcPasscodeText">
            Your new BTC address is:
          </Typography>
          <CopyToClipboard copyText={address} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              gap: '16px',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
            <Button
              variant="contained"
              onClick={completeMigration}
              disabled={fetchWalletLoading}
            >
              {fetchWalletLoading ? <LoadingSpinner /> : 'Done '}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
