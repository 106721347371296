import * as auth from '@nerdcoresdk/nerd-core-auth';
import { onIdTokenChanged } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
// Hooks
import { useFetcher } from '../hooks/useFetcher';
import { useFeatureFlags } from './FeatureFlagProvider';
// Utils
import { brand } from '@/brand/brand';
import { isBrandAmbassador } from '@/resources/brand-ambassador-service.resource';
import {
  generateCoreBypassToken,
  getMemberDetails,
  getMemberPreferences,
} from '@/resources/membership-service.resource';
import { buildMemberDataObj } from '@/utils/user';
import { deleteCookie, getCookie, hasCookie, setCookie } from 'cookies-next';
import { config } from '../config';
import { setTokenRefreshInterval } from '../utils';

const {
  createRecaptcha,
  disableMFA,
  enableMFA,
  enrichToken,
  fetchNodePurchaseAgreements,
  fetchUserAgreements,
  firebaseAuth,
  refreshTheToken,
  resetPassword,
  resetPasswordFromEmail,
  sendForgotPasswordEmail,
  sendLoginMFA,
  signout,
  updateFirebaseToken,
} = auth.configure({
  environment: config.environment,
  firebaseConfig: config.firebase,
  brandConfig: config.brand,
  coreConfig: config.core,
});

const authStatuses = {
  PENDING: 'pending',
  SIGNED_IN: 'signedIn',
  SIGNED_OUT: 'signedOut',
};

const enrollmentPhases = {
  PENDING: 'pending',
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
};

const times = {
  IDLE_TIMEOUT: 1000 * 60 * 20,
  // 👇 DO NOT SET FOR MORE THAN 59 MINUTES (firebase default token expiration is 60 minutes)
  TOKEN_REFRESH_RATE: 1000 * 60 * 50,
};

const initMemberPref = {
  isEnrolled: false,
  hasPref: {},
  optedOut: false,
  hasCookies: false,
};

const init2FA = {
  updated: false,
  value: false,
};

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [status, setStatus] = useState(authStatuses.PENDING);
  const [enrollmentPhase, setEnrollmentPhase] = useState(enrollmentPhases.PENDING);
  const [refreshInterval, setRefreshInterval] = useState('');
  const [isIdleModalShowing, setIsIdleModalShowing] = useState(false);
  const [memberPreferences, setMemberPreferences] = useState(initMemberPref);
  const [authLoading, setAuthLoading] = useState(true);
  const [show2fa, setShow2fa] = useState(init2FA);
  const [memberData, setMemberData] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [createRememberMeToken, setCreateRememberMeToken] = useState(false); // We set this property in this hook since we don't want to create the remember me token until after the user successfully signs in
  const [isUserEnablingAuthenticator, setIsUserEnablingAuthenticator] = useState(false);
  const [attemptAutoLogin, setAttemptAutoLogin] = useState(false);
  const { featureFlags } = useFeatureFlags();

  const handleIdleSession = async () => {
    if (status === authStatuses.SIGNED_IN) {
      // close 2fa modal if open when session expires
      show2fa && setShow2fa(false);
      await signout();
      clearSessionState();
      setIsIdleModalShowing(true);
    }
  };

  const { start: startIdleTimer, reset: resetIdleTimer } = useIdleTimer({
    timers: workerTimers,
    timeout: times.IDLE_TIMEOUT,
    startManually: true,
    stopOnIdle: true,
    onIdle: handleIdleSession,
  });

  const fetcher = useFetcher({ user });

  const handleUpdateMemberData = async (token) => {
    const userMemberData = jwtDecode(token);
    // Use the token as the source of truth for user data
    const memberDataObj = buildMemberDataObj(userMemberData);
    // Add profile picture to member data object
    await getMemberDetails(token)
      .then((data) => {
        memberDataObj.photo =
          data?.data?.profilePicture?.length > 0 ? data?.data?.profilePicture : '';
      })
      .catch(() => {
        // Catch error and fail silently
      });

    // add brandAmbassador to memberDataObj
    try {
      memberDataObj.isBrandAmbassador = (await fetcher(isBrandAmbassador())) || false;
    } catch (err) {
      memberDataObj.isBrandAmbassador = false;
    }

    if (localStorage.getItem('esp') === 'true') {
      memberDataObj.canPurchaseNodes = true;
    }

    setMemberData(memberDataObj);
  };

  const clearSessionState = () => {
    resetIdleTimer();
    clearInterval(refreshInterval);
    setRefreshInterval('');
    setUser({});
    setStatus(authStatuses.SIGNED_OUT);
    setEnrollmentPhase(enrollmentPhases.PENDING);
    setAuthLoading(true);
    setMemberData(null);
    setIsLoggingIn(false);
    setShow2fa(init2FA);
  };

  useEffect(() => {
    if (user?.accessToken?.length > 0 && featureFlags?.BypassTokens) {
      setCookie('userCookieAccessToken', user.accessToken, {
        domain: brand.cookiesDomain,
        expires: brand.cookiesExpiration,
      });
      if (createRememberMeToken) {
        generateBypassToken('remember');
        setCreateRememberMeToken(false);
      }
    }
  }, [user.accessToken, featureFlags]);

  // Destroy all of the cookies if brand switches setting for ByPassTokens
  useEffect(() => {
    if (featureFlags?.BypassTokens !== undefined && !featureFlags?.BypassTokens) {
      if (hasCookie('coreBypassToken') || hasCookie('userCookieAccessToken')) {
        deleteSSOCookies(true);
      }
    }
  }, [featureFlags, user]);

  const generateBypassToken = async (tokenType) => {
    if (!hasCookie('coreBypassToken')) {
      const bypassTokenData = await fetcher(
        generateCoreBypassToken({ tokenType: tokenType })
      );
      setCookie('coreBypassToken', bypassTokenData.tokenValue, {
        domain: brand.cookiesDomain,
        expires: new Date(bypassTokenData.expiresAt),
      });
    }
  };

  const deleteSSOCookies = (deleteBypassCookie = false) => {
    deleteCookie('userCookieAccessToken', {
      domain: brand.cookiesDomain,
    });
    if (deleteBypassCookie) {
      deleteCookie('coreBypassToken', {
        domain: brand.cookiesDomain,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebaseAuth, async (_user) => {
      if (isUserEnablingAuthenticator) {
        deleteSSOCookies(true);
      } else if (featureFlags?.BypassTokens) {
        handleAutoLoginUser();
        // This will only be undefined if it is either still loading the feature flag or if the feature flag isn't set in the database
      } else if (featureFlags?.BypassTokens === undefined) {
        setAttemptAutoLogin(true);
      }
      if (!_user) {
        if (!isUserEnablingAuthenticator) {
          clearSessionState();
        }
      } else {
        handleSetUser(_user);
      }
    });
    return () => {
      unsubscribe();
      clearInterval(refreshInterval); // Ensure interval is cleared on unmount
    };
  }, []);

  useEffect(() => {
    if (featureFlags?.BypassTokens) {
      handleAutoLoginUser();
    }
  }, [featureFlags]);

  const handleSetUser = (newUser) => {
    const userMemberData = jwtDecode(newUser.accessToken);

    if (userMemberData.emailVerified && userMemberData.permissions) {
      setUser(newUser);
      setStatus(authStatuses.SIGNED_IN);

      startIdleTimer();
      clearInterval(refreshInterval); // clear any intervals to avoid duplication
      const tokenRefreshInterval = setTokenRefreshInterval.getInstance({
        user: newUser,
        refreshTheToken,
        currentExpiration: userMemberData.exp,
        tokenRefreshRate: times.TOKEN_REFRESH_RATE,
        handleIdleSession,
      });
      setRefreshInterval(tokenRefreshInterval);
      userMemberData.agreementsSigned
        ? setEnrollmentPhase(enrollmentPhases.COMPLETED)
        : setEnrollmentPhase(enrollmentPhases.INCOMPLETE);
    }
  };

  const handleAutoLoginUser = async () => {
    const cookieUserToken = getCookie('userCookieAccessToken');
    const coreBypassToken = getCookie('coreBypassToken');
    if (
      attemptAutoLogin &&
      cookieUserToken?.length > 0 &&
      coreBypassToken?.length > 0 &&
      (!user || (user && Object.keys(user)?.length === 0))
    ) {
      const newUser = await enrichToken({
        token: getCookie('userCookieAccessToken'),
        method: 'GET',
        bypassToken: getCookie('coreBypassToken'),
      });
      newUser.accessToken = cookieUserToken;

      handleSetUser(newUser);
      // If the user token is to big, the cookie can truncate it to not have all information. To bypass this, we simply refresh the page
      window.location.reload();
    }
    setAttemptAutoLogin(false);
  };

  const handleGetMember2faPref = async (isLoggingIn) =>
    await fetcher(getMemberPreferences())
      .then((preferences) => {
        const mfaPreference = preferences.find((preference) => preference.name === 'mfa');
        const isEnrolled = mfaPreference?.value === 'true';
        const optedOut = preferences.find(
          (preference) => preference.name === 'mfaOptOut'
        );
        const hasCookies = preferences.find(
          (preference) => preference.name === 'essential'
        );

        setMemberPreferences({
          isEnrolled,
          hasPref: mfaPreference || {},
          optedOut: optedOut,
          hasCookies: !!hasCookies,
        });
        setShow2fa({
          updated: true,
          value:
            !isEnrolled &&
            !memberData?.appAuthEnabled &&
            isLoggingIn &&
            (optedOut?.value === 'false' || !optedOut),
        });
      })
      .catch(() => {
        // catch error and fail silently
      })
      .finally(() => setAuthLoading(false));

  const update2faPrefState = useCallback(
    (isEnrolled) => setMemberPreferences((prev) => ({ ...prev, isEnrolled })),
    []
  );

  useEffect(() => {
    if (status === authStatuses.SIGNED_IN && user?.accessToken) {
      handleGetMember2faPref(isLoggingIn);
      handleUpdateMemberData(user?.accessToken);
    }
  }, [status, isLoggingIn, user?.accessToken]);

  const value = {
    authLoading,
    authStatuses,
    createRecaptcha,
    disableMFA,
    enableMFA,
    enrichToken,
    enrollmentPhase,
    enrollmentPhases,
    fetcher,
    fetchNodePurchaseAgreements,
    fetchUserAgreements,
    firebaseAuth,
    handleUpdateMemberData,
    init2FA,
    isIdleModalShowing,
    isLoggingIn,
    memberData,
    memberPreferences,
    refreshTheToken,
    resetPassword,
    resetPasswordFromEmail,
    sendForgotPasswordEmail,
    sendLoginMFA,
    setIsIdleModalShowing,
    setIsLoggingIn,
    setShow2fa,
    show2fa,
    status,
    update2faPrefState,
    updateFirebaseToken,
    user,
    generateBypassToken,
    setCreateRememberMeToken,
    isUserEnablingAuthenticator,
    setIsUserEnablingAuthenticator,
    deleteSSOCookies,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
