import { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useRouter } from 'next/router';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import * as auth from '@nerdcoresdk/nerd-core-auth';
import { useCartContext } from '@/providers/CartProvider';
import { useCharter } from '@/hooks/useCharter';
import { useWallet } from '@/hooks/useWallet';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
import { useCookies } from '@/hooks/useCookies';
// Utils
import { routes } from '@/routes/routes';
import {
  createMemberPreference,
  updateMemberPreference,
} from '@/resources/membership-service.resource';
import {
  getMemberCampaigns,
  updateMemberCampaign,
} from '@/resources/rewards-service.resource';
// Helpers
import {
  notifications,
  initNavBadges,
  initNotificationModals,
  initMemberRewards,
  buildOffersObject,
  buildRewardsNoticeObject,
  handleFetchIds,
} from './helpers/appInfoHelpers';

export const AppInfoContext = createContext({});

export const useAppInfo = () => useContext(AppInfoContext);

const AppInfoProvider = ({ children }) => {
  const router = useRouter();
  const {
    authStatuses,
    fetcher,
    isLoggingIn,
    init2FA,
    memberData,
    memberPreferences,
    setIsIdleModalShowing,
    setIsLoggingIn,
    setShow2fa,
    show2fa,
    status,
  } = useAuth();
  const { signout } = auth.getInstance();
  const { cartTotals } = useCartContext();
  const { featureFlags } = useFeatureFlags();
  const { getCharterInfo, votingEnded, hasVoted, error } = useCharter();
  const { coreWalletCoinState, hasNoWallet, walletCoinState, btcMigRequired } =
    useWallet();
  const { handleGetPrefCookies } = useCookies();

  const [navBadges, setNavBadges] = useState(initNavBadges);
  const [notificationModals, setNotificationModals] = useState(initNotificationModals);
  const [currentOffers, setCurrentOffers] = useState([]);
  const [memberRewards, setMemberRewards] = useState(initMemberRewards);
  const walletLoaded = !walletCoinState.loading && !coreWalletCoinState.loading;

  // Clear badges and modals on logout
  useEffect(() => {
    if (status !== authStatuses.SIGNED_IN) {
      setNavBadges(initNavBadges);
      setNotificationModals(initNotificationModals);
      setMemberRewards(initMemberRewards);
    } else {
      if (memberData?.id) {
        handleGetPrefCookies();
        // if user is logged in, fetch offers and charter data for notifications/badges
        getOffers();
        getCharterInfo();
      }
    }
  }, [status, memberData?.id]);

  const gtagReferralShareEvent = ({ offer, sponsor }) => {
    if (typeof window === 'undefined') return;

    window.dataLayer.push({
      event: `Referral Share Click`,
      offerTitle: offer?.title ?? offer?.label,
      offerUrl: offer.url,
      sponsor,
    });
  };

  // campaign/offers
  const getOffers = () => {
    // fetch current promotions and user reward progress
    fetcher(getMemberCampaigns({ id: memberData?.id }))
      .then((data) => {
        const offers = buildOffersObject(data);
        setCurrentOffers(offers);
        setMemberRewards(buildRewardsNoticeObject(offers));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdateReward = ({ rewardType }) => {
    const rewardIds = handleFetchIds(currentOffers, rewardType);

    fetcher(updateMemberCampaign({ id: memberData?.id, rewardIds: rewardIds })).catch(
      (error) => {
        // if for some reason we can't mark the promotion as viewed, what do we do?
        console.error(error);
      }
    );
    if (rewardType === 'smart-wallet') {
      setMemberRewards((prevState) => ({
        ...prevState,
        signupNodeReward: true,
      }));
      handleSetNavBadges({ id: 'nodes', value: 'NEW' });
    }
  };

  const handleSetRewardType = (id, state, type) => {
    setNotificationModals((prevState) => ({
      ...prevState,
      [id]: { show: state, type: type },
    }));
  };

  // Navigation badges
  const handleSetNavBadges = ({ id, value }) => {
    setNavBadges((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    // nav badges are for logged in user navigation layout links to inform the user of items needing attention, MUI badge expects a string
    if (memberData?.userId && walletLoaded) {
      setNavBadges({
        cart: cartTotals?.cartQuantity === 0 ? '' : `${cartTotals?.cartQuantity}`, // cart item count
        nodes: memberRewards.referralNodeReward ? 'NEW' : '', // if new node reward is given and had not been viewed
        store: '', // 'NEW' if has smart node credit to be used
        twoFactor: memberPreferences?.isEnrolled || memberData?.appAuthEnabled ? '' : '1', // has not set up 2fa
        voting: !votingEnded && !hasVoted && !error ? '1' : '0', // charter open and user has not voted yet
        wallet: hasNoWallet ? 'NEW' : '', // has no wallet
      });
    }
  }, [
    btcMigRequired,
    cartTotals?.cartQuantity,
    error,
    hasNoWallet,
    hasVoted,
    memberData,
    memberPreferences,
    memberRewards,
    votingEnded,
    walletCoinState,
    walletLoaded,
  ]);

  useEffect(() => {
    // Notifications are for user prompts (currently via modals)
    show2fa.updated &&
      memberRewards.updated &&
      isLoggingIn &&
      walletLoaded &&
      handleNotifications();
  }, [memberRewards, show2fa, walletLoaded]);

  // handle modals
  const handleSessionModalClose = useCallback(() => {
    setIsIdleModalShowing(false);
    if (status === authStatuses.SIGNED_IN) {
      signout();
    }
  }, [setIsIdleModalShowing]);

  const handleEnroll2fa = () => {
    setShow2fa(init2FA);
    router.replace(routes.manageAccountTwoFactorAuth.path);
  };

  const handleHide2fa = ({ optOut }) => {
    if (optOut) {
      !memberPreferences.optedOut
        ? fetcher(createMemberPreference('mfaOptOut', optOut))
        : fetcher(
            updateMemberPreference('mfaOptOut', optOut, memberPreferences.optedOut.id)
          );
    }
    setIsLoggingIn(false);
    setShow2fa(init2FA);
    router.asPath.includes('store')
      ? router.replace({
          pathname: router.asPath.split('?')[0],
          query: { id: router.query.id, showModal: true },
        })
      : router.replace(router.pathname, undefined, { shallow: true });
  };

  const handleNotifications = async () => {
    // check for wallet needed modal

    if (hasNoWallet) {
      featureFlags['Rewards.Offers.Show']
        ? handleSetRewardType('walletOffer', true, notifications.walletOffer) // wallet message includes wording about free node after wallet setup
        : handleSetRewardType('wallet', true, notifications.wallet); // setup wallet modal, no mention of offer
    }
    // user BTC wallet is flagged as testnet in prod
    else if (btcMigRequired) {
      handleSetRewardType('btcAddressFlag', true, notifications.btcAddressFlag);
    }
    // check if reward has been granted
    else if (memberRewards.referralNodeReward) {
      return handleSetRewardType('referralNode', true, notifications.referralNode);
    }
    // if no other modal to show, check for 2fa modal needed
    else {
      show2fa.value && handleSetRewardType('twoFactor', true, notifications.twoFactor);
    }
    // update state so modals don't appear on refresh/manual url change
    setIsLoggingIn(false);
  };

  return (
    <AppInfoContext.Provider
      value={{
        currentOffers,
        gtagReferralShareEvent,
        handleEnroll2fa,
        handleHide2fa,
        handleSessionModalClose,
        handleSetNavBadges,
        handleSetRewardType,
        handleUpdateReward,
        memberRewards,
        navBadges,
        notificationModals,
        notifications,
      }}
    >
      {children}
    </AppInfoContext.Provider>
  );
};

export default AppInfoProvider;
