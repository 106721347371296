import { useEffect, useReducer, useState } from 'react';
// Hooks
import { useAuth } from './useAuth';
// Resources
import {
  getBlockchainConfigurations,
  getExternalNfts,
  getNftCollections,
  transferNftGasFee,
  getNfts,
  transferNft,
} from '@/resources/nft-service.resource';
// Utils
import {
  initNftState,
  initNftGasState,
  initError,
  nftReducer,
  nftErrorHandler,
} from './helpers/nftHelpers';

export function useNfts({ chainId }) {
  const { fetcher } = useAuth();

  const [state, dispatch] = useReducer(nftReducer, initNftState);
  const [gasFee, setGasFee] = useState(initNftGasState);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    getBlockchainsAndCollections();
  }, []);

  const handleSetGasState = (data) =>
    setGasFee((prevState) => ({
      ...prevState,
      ...data,
    }));

  const setNftError = (error = false) =>
    dispatch({ type: 'SET_ERROR', payload: error ?? initError });

  const getBlockchainsAndCollections = async () =>
    await fetcher(getBlockchainConfigurations())
      .then(({ blockchainConfigurations }) => {
        const blockchains = {};

        blockchainConfigurations.forEach((blockchain) => {
          blockchains[blockchain.chainId] = blockchain;
        });
        return blockchains;
      })
      .then(async (blockchains) => {
        const collections = await fetcher(getNftCollections());

        collections.forEach((collection) => {
          blockchains[collection.chainId] = {
            ...blockchains[collection.chainId],
            collections: {
              ...blockchains[collection.chainId]?.collections,
              [collection.contractAddress]: {
                ...collection,
                nfts: {},
              },
            },
          };
        });

        const collectionsArray = collections.map((collection) => ({
          ...collection,
          network: blockchains[collection.chainId]?.name,
        }));

        dispatch({
          type: 'SET_BLOCKCHAINS',
          payload: blockchains,
        });
        dispatch({ type: 'SET_CONTRACT_IDS', payload: collectionsArray });
      });

  const buildNftUrls = (chainId, collection, id) => {
    const blockchain = state.blockchains[chainId];
    const url = blockchain?.blockExplorerUrl;
    const isL2 = blockchain?.isDefaultLayer2;
    return {
      collection: `${url}/token/${collection}`,
      token: `${url}/${isL2 ? 'collections' : 'nft'}/${collection}/${id}`,
    };
  };

  const handleFetchInternalNfts = async ({
    walletAddress,
    contractIds,
    isCore = true,
  }) => {
    if (!contractIds?.length) return [];
    const promises = contractIds.map(
      async ({ contractAddress: contractId, chainId, internalName, network }) =>
        await fetcher(getNfts({ address: walletAddress, chainId, contractId }))
          .then((nfts) => {
            if (!nfts.length) return [];

            return nfts?.map((nft) => ({
              ...nft,
              contractId,
              chainId,
              isCore,
              network,
              isExternal: false,
              ownedBy: walletAddress,
              collection: internalName || '',
              links: buildNftUrls(chainId, contractId, nft.id),
              useEthGas: state.blockchains[chainId].isDefaultLayer1,
            }));
          })
          .catch(nftErrorHandler(setNftError))
    );
    const responses = await Promise.all(promises);
    const nfts = responses.reduce((acc, val) => [...acc, ...val], []);

    return nfts;
  };

  const handleFetchExternalNfts = async ({ walletAddress, chainId, isCore = true }) =>
    await fetcher(getExternalNfts({ chainId, address: walletAddress }))
      .then((nfts) => {
        if (!nfts.length) return [];
        return nfts?.map((nft) => ({
          ...nft,
          chainId,
          isCore,
          ownedBy: walletAddress,
          contractId: nft.contractAddress,
          isExternal: true,
          links: buildNftUrls(chainId, nft.contractAddress, nft.id),
          useEthGas: true,
        }));
      })
      .catch(nftErrorHandler(setNftError));

  const fetchNfts = async ({ walletAddress, isCore = true }) => {
    const { contractIds } = state;

    const nfts = await handleFetchInternalNfts({ contractIds, walletAddress, isCore });
    const externalNfts = await handleFetchExternalNfts({
      walletAddress,
      chainId,
      isCore,
    });

    const filteredNfts = externalNfts.filter((nft) => !nfts.some((n) => n.id === nft.id));

    dispatch({
      type: isCore ? 'SET_CORE_NFT_STATE' : 'SET_LEGACY_NFT_STATE',
      payload: { nfts, externalNfts: filteredNfts },
    });
  };

  const handleGetNftsByWallet = async ({
    core,
    legacy,
    hasTwoWallets,
    hasNoWallet,
    hasLegacyOnly,
    isRefreshing = false,
  }) => {
    if (hasNoWallet) return;
    setIsRefreshing(isRefreshing);

    if (hasTwoWallets) {
      await fetchNfts({ walletAddress: core });
      await fetchNfts({ walletAddress: legacy, isCore: false });
    } else {
      await fetchNfts({
        walletAddress: hasLegacyOnly ? legacy : core,
        isCore: !hasLegacyOnly,
      });
    }
    dispatch({ type: 'SET_CORE_NFT_STATE', payload: { loading: false } });
    dispatch({ type: 'SET_LEGACY_NFT_STATE', payload: { loading: false } });
    dispatch({ type: 'SET_TRANSFER_SUCCESS', payload: false });
    setIsRefreshing(false);
  };

  // Fetch transfer gas fee
  const fetchGasFee = async ({ data }) => {
    handleSetGasState({ loading: true });

    await fetcher(transferNftGasFee({ ...data })).then((value) => {
      handleSetGasState({
        value,
        loading: false,
        error: initError,
      });
    });
  };

  const handleTransfer = async ({ data }) =>
    await fetcher(transferNft({ ...data })).then(() => {
      handleSetGasState(initNftGasState);
      dispatch({ type: 'SET_TRANSFER_SUCCESS', payload: true });
    });

  const findNftByIds = (id, contractId) => {
    const coreNfts = [...state.core.nfts, ...state.core.externalNfts];
    const legacyNfts = [...state.legacy.nfts, ...state.legacy.externalNfts];
    const allNfts = [...coreNfts, ...legacyNfts];

    const nftMatch = (nft) => nft.id === id && nft.contractId === contractId;
    const nft = allNfts.find(nftMatch);
    return nft;
  };

  return {
    dispatch,
    fetchGasFee,
    fetchNfts,
    findNftByIds,
    gasFee,
    handleTransfer,
    state,
    handleGetNftsByWallet,
    setNftError,
    isRefreshing,
    resetGasFee: () => handleSetGasState(initNftGasState),
    clearError: () => setNftError(),
  };
}
