import {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useNfts } from '@/hooks/useNfts';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
// Utils
import { handleGetWallet, handleGetChainInfo } from '@/utils/wallet';
import { btcMigrationRequired } from '@/resources/wallet-service.resource';
import { brand } from '@/brand/brand';
import { config } from 'config';
// Helpers
import {
  walletReducer,
  initialWalletState,
  initErrorState,
} from './helpers/walletHelpers';

export const WalletContext = createContext({});

export const useWalletContext = () => useContext(WalletContext);

const initWalletInfo = {
  core: null,
  legacy: null,
  loading: false,
  hasTwoWallets: false,
  hasNoWallet: false,
  hasLegacyOnly: false,
  hasMigrated: false,
};

const WalletProvider = ({ children }) => {
  const router = useRouter();
  const { featureFlags } = useFeatureFlags();
  const { fetcher, status, authStatuses } = useAuth();

  const [state, dispatch] = useReducer(walletReducer, initialWalletState);
  const [walletError, setWalletError] = useState(initErrorState);
  const [coreWalletError, setCoreWalletError] = useState(initErrorState);
  const [brandCoins, setBrandCoins] = useState(null);
  const [chainInfo, setChainInfo] = useState(null);
  const [walletInfo, setWalletInfo] = useState(initWalletInfo);
  const [btcMigRequired, setBtcMigRequired] = useState(false);

  const serverError = walletError.server || coreWalletError.server;
  const isWalletLoading =
    state.walletCoinState.loading || state.coreWalletCoinState.loading;

  const hasCoreWallet =
    state.coreWalletCoinState.hasWallet &&
    !state.coreWalletCoinState.loading &&
    !serverError;

  const hasNoWallet =
    !state.walletCoinState.hasWallet &&
    !hasCoreWallet &&
    !state.walletCoinState.loading &&
    !serverError;

  const shouldUseCore = hasCoreWallet || hasNoWallet || !featureFlags.LegacyWallets;

  // Find out if we are using the legacy wallet or core wallet
  const getIsCore = router.query?.core ? router.query?.core === 'true' : shouldUseCore;

  const userWalletId = hasCoreWallet
    ? state.coreWalletCoins[0]?.walletId
    : state.walletCoins[0]?.walletId;

  const getWalletInfo = () => {
    const core = state.coreWalletCoins[0]?.walletId;
    const legacy = state.walletCoins[0]?.walletId;
    const loading = state.walletCoinState.loading || state.coreWalletCoinState.loading;
    return {
      core,
      legacy,
      loading,
      hasTwoWallets: !!core && !!legacy && core !== legacy,
      hasNoWallet: !core && !legacy,
      hasLegacyOnly: !core && !!legacy,
      hasMigrated: !!core && !!legacy && core === legacy,
    };
  };

  const nft = useNfts({
    chainId: chainInfo?.chainId,
    walletInfo: getWalletInfo(),
  });

  const handleSetError = (error) => {
    if (error === 'reset') {
      setWalletError(initErrorState);
      setCoreWalletError(initErrorState);
      return;
    }
    getIsCore
      ? setCoreWalletError((prev) => ({ ...prev, ...error }))
      : setWalletError((prev) => ({ ...prev, ...error }));
  };

  const findCoin = (symbol) => {
    const wallet = getIsCore ? state.coreWalletCoins : state.walletCoins;
    return wallet?.find((coin) => coin?.coinSymbol === symbol);
  };

  const getWalletItems = (isLoading) =>
    handleGetWallet(
      isLoading,
      true,
      fetcher,
      dispatch,
      findCoin,
      handleSetError,
      featureFlags.ShowBridge
    );

  // Wallet 2.0
  const coreGetWalletItems = (isLoading) =>
    handleGetWallet(
      isLoading,
      false,
      fetcher,
      dispatch,
      findCoin,
      handleSetError,
      featureFlags.ShowBridge
    );

  const handleGetActiveWallets = async (setIsLoading = false) => {
    // If legacy wallets are disabled, only get core wallets
    if (!featureFlags.LegacyWallets) {
      await coreGetWalletItems(setIsLoading);
    } else {
      if (walletInfo.hasTwoWallets) {
        await getWalletItems(setIsLoading);
        await coreGetWalletItems(setIsLoading);
      } else if (walletInfo.hasLegacyOnly) await getWalletItems(setIsLoading);
      else await coreGetWalletItems(setIsLoading);
    }
  };

  const handleClearWallet = () => {
    dispatch({ type: 'CLEAR_WALLET' });
    setBrandCoins(null);
    setWalletInfo(initWalletInfo);
    nft.dispatch({ type: 'RESET' });
    nft.resetGasFee();
  };

  const handleGetBtcMigRequired = async () => {
    await fetcher(btcMigrationRequired())
      .then((data) => {
        setBtcMigRequired(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const chainIdIntervalRef = useRef(null);
  useEffect(() => {
    const fetchAndSetChainId = async () => {
      if (status !== authStatuses.SIGNED_IN) {
        if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
        return;
      }

      const attemptFetch = async () => {
        const chainInfo = await handleGetChainInfo(fetcher);
        if (chainInfo) {
          setChainInfo(chainInfo);
          if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
          return true;
        }
        return false;
      };

      const success = await attemptFetch();
      if (!success) {
        // If the first attempt fails, start retrying every 20 seconds
        chainIdIntervalRef.current = setInterval(attemptFetch, 20000);
      }
    };

    fetchAndSetChainId();

    return () => {
      if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
    };
  }, [status, authStatuses.SIGNED_IN]);

  // Init get user wallets
  useEffect(() => {
    // Clear wallet(s) on user logout
    if (status !== authStatuses.SIGNED_IN) {
      handleClearWallet();
      nft.dispatch({ type: 'RESET' });
      nft.resetGasFee();
      return;
    }

    handleGetBtcMigRequired();

    featureFlags.LegacyWallets
      ? getWalletItems()
      : dispatch({ type: 'SET_WALLET_LOADING', payload: false });
    coreGetWalletItems();

    const intervalRate = config.environment !== 'production' ? 180000 : 60000;

    const updateWalletInterval = setInterval(handleGetActiveWallets, intervalRate);

    return () => clearInterval(updateWalletInterval);
  }, [status]);

  useEffect(() => {
    if (isWalletLoading) return;
    const walletData = getWalletInfo();
    setWalletInfo(walletData);
    nft.handleGetNftsByWallet({ ...walletData });
  }, [isWalletLoading]);

  // Set brand coin
  useEffect(() => {
    const isCore = getIsCore;
    setBrandCoins({
      isCore,
      l1: findCoin(brand.coin, !isCore),
      l2: findCoin(`${brand.coin}-P`, !isCore),
    });
  }, [state.walletCoins, state.coreWalletCoins, router.query?.core]);

  return (
    <WalletContext.Provider
      value={{
        brandCoins,
        btcMigRequired,
        chainInfo,
        coreGetWalletItems,
        coreWalletError,
        findCoin,
        getIsCore,
        getWalletItems,
        handleClearWallet,
        handleGetActiveWallets,
        hasCoreWallet,
        hasNoWallet,
        isWalletLoading,
        nft,
        serverError,
        userWalletId,
        walletError,
        walletInfo,
        coreWalletCoins: state.coreWalletCoins,
        coreWalletCoinState: state.coreWalletCoinState,
        hasDuplicateWallets: state.hasDuplicateWallets,
        walletCoins: state.walletCoins,
        walletCoinState: state.walletCoinState,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
