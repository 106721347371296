import { useState } from 'react';
import { Box, Typography } from '@mui/material';
// Components
import { ModalBody } from '@/common/index';
import UpdateBTCForm from './UpdateBTCForm';
// Utils
import { brand } from '@/brand/brand';

export default function BTCAddressModal({ onClose }) {
  const [view, setView] = useState(0);

  const viewTab = [
    {
      header: `Update Wallet`,
      buttonPrimaryText: 'Continue',
      buttonPrimaryOnClick: () => setView(1),
      buttonSecondaryText: 'Cancel',
      buttonSecondaryOnClick: () => onClose(),
      body: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>
            During the initial setup of some users&apos; wallets, the Bitcoin address was
            incorrectly derived using the testnet derivation path. Your BTC public address
            was identified by our team as one of the affected wallets. Your funds for all
            of your wallets are safe and this is not a security issue. However, you may
            have noticed an error when attempting to view your BTC address, due to the
            wallet address being testnet derived.
          </Typography>
          <Typography>
            To resolve this, we need to generate your BTC mainnet address using your
            mnemonic. Since {brand.brandName} wallets are non-custodial, only you hold the
            passcode that allows for the recovery of your mnemonic to generate of the BTC
            address. This will occur locally on your computer and NO SENSITIVE INFORMATION
            will be transmitted.
          </Typography>
          <Typography>
            You may skip this if you do not wish to use the BTC address, however, you will
            be prompted on login each time as a reminder until the new address is
            generated.
          </Typography>
        </Box>
      ),
    },
    {
      header: `Important ${brand.brandName} Wallet Update`,
      body: <UpdateBTCForm handleClose={onClose} />,
    },
  ];

  return (
    <ModalBody
      severity={view === 0 ? 'warn' : ''}
      showClose
      header={viewTab[view].header}
      buttonPrimaryText={viewTab[view].buttonPrimaryText}
      buttonPrimaryOnClick={viewTab[view].buttonPrimaryOnClick}
      buttonSecondaryText={viewTab[view].buttonSecondaryText}
      buttonSecondaryOnClick={viewTab[view].buttonSecondaryOnClick}
      handleClose={onClose}
      body={viewTab[view].body}
    />
  );
}
