export const notifications = {
  twoFactor: 'twoFactor',
  wallet: 'wallet',
  walletOffer: 'walletOffer',
  newUser: 'newUser',
  referralNode: 'referralNode',
  purchaseCredit: 'purchaseCredit',
  btcAddressFlag: 'btcAddressFlag',
};

export const initNavBadges = {
  cart: '',
  nodes: '',
  store: '',
  twoFactor: '',
  voting: '',
  wallet: '',
};

export const initMemberRewards = {
  updated: false,
  signupNodeReward: false,
  referralNodeReward: false,
};

export const initNotificationModals = {
  twoFactor: { show: false, type: '' },
  wallet: { show: false, type: '' },
  reward: { show: false, type: '' },
  btcAddressFlag: { show: false, type: '' },
};

export function buildOffersObject(data) {
  const promos = {};
  // build promos object with promo id key, object value
  data.campaigns.forEach((campaign) => {
    campaign.definition.promotions.forEach((promo) => {
      promos[promo.id] = promo;
    });
  });

  data.campaigns?.forEach((campaign) => {
    // For each campaign promotion reward id -> move the progress of the matching rewardDefinitionId into that rewards object progress key, array value
    campaign.progress?.promotions?.forEach((promo) => {
      promos[promo.promotionId]?.rewards?.forEach((reward) => {
        reward.progress = [];
        promo?.redemptions?.map((currentReward) => {
          currentReward?.rewardProgresses?.map((rewardProg) => {
            // match the reward to the promotion rewards
            if (rewardProg?.rewardDefinitionId === reward?.id) {
              reward.progress.push(rewardProg);
            }
          });
        });
      });
    });
  });
  return Object.values(promos);
}

export function buildRewardsNoticeObject(data) {
  const smartWalletOffer = data.find((offer) => {
    return offer.description === 'smart-wallet';
  });

  const hasSmartWalletNode = !!smartWalletOffer?.rewards.find((progressItem) => {
    return progressItem?.progress?.find((reward) => {
      return reward?.requirementsCompleted;
    });
  });

  const hasNodeRewardToView = getNodesRewardsToView(data);

  return {
    updated: true,
    signupNodeReward: hasSmartWalletNode,
    referralNodeReward: hasNodeRewardToView.length > 0,
  };
}

const getNodesRewardsToView = (data) => {
  const referNodesEarnedNotViewed = [];
  data.forEach((offer) => {
    if (offer.description === 'node-discount') {
      offer.rewards?.forEach((progressItem) => {
        // for each offer, get the progress items
        progressItem?.progress?.forEach((progress) => {
          // if the item is fulfilled - and has not been viewed by the user, push the memberReward id to the array
          !progress?.viewed &&
            progress.fulfilled &&
            referNodesEarnedNotViewed.push(progress?.memberRewardId);
        });
      });
    }
  });
  return referNodesEarnedNotViewed;
};

export function handleFetchIds(currentOffers, rewardType) {
  // offer rewards [{}] progress [{}]
  const ids = [];
  // for each reward that has been fulfilled, grab the id of those rewards to mark them as viewed when the user sees the modal
  currentOffers.find((offer) => {
    if (offer.description === rewardType) {
      return offer.rewards?.forEach((reward) => {
        reward.progress?.forEach((reward) => {
          ids.push(reward.memberRewardId);
        });
      });
    }
  });
  return ids;
}
